<template>
  <div class="faqs">
    <Tabs :tabs="sections"></Tabs>
    <router-view />
  </div>
</template>

<script>
import faqsSections from '@/config/faqsSections';
import Tabs from '@/components/Tabs.vue';

export default {
  name: 'Faqs',
  data() {
    return {
      sections: faqsSections,
    };
  },
  components: {
    Tabs,
  },
};
</script>
