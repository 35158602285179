import i18n from '@/i18n';

const faqSections = [
  {
    label: i18n.t('faq.products'),
    to: { name: 'FaqIndex', params: { section: 'products' } },
  },
  {
    label: i18n.t('faq.investmentAdvisory'),
    to: { name: 'FaqIndex', params: { section: 'investment-advisory' } },
  },
  {
    label: i18n.t('faq.other'),
    to: { name: 'FaqIndex', params: { section: 'other' } },
  },
];

export default faqSections;
